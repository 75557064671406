import * as React from 'react';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import type { OurValuesSection } from '../shared';
import * as styles from '../our_values.module.scss';

export const GoodForYou = ({ image, sections, title }: OurValuesSection) => {
  return (
    <Box className={styles.goodForYou}>
      <Container>
        <Box className={styles.inner}>
          <div className={styles.image}>
            <div
              className={styles.imageFullHeightOffset}
              style={{ backgroundImage: `url(${image?.publicURL})` }}
            />
          </div>
          <div className={styles.content}>
            <h2>{title}</h2>
            <Box className={styles.sections}>
              {sections.map((section, index) => (
                <Box key={index} className={styles.section}>
                  <h4>{section.title}</h4>
                  <Box htmlContent={section.body ?? ''} />
                </Box>
              ))}
            </Box>
          </div>
        </Box>
      </Container>
    </Box>
  );
};
