import React from 'react';
import Box from '@webAtoms/Box/Box';
import PageHeading from '@webMolecules/PageHeading/PageHeading';
import UpNext from '@webMolecules/UpNext/UpNext';
import Steps, { StepsProps } from './Sections/Steps';
import VisitOurDisplay from '@webSections/VisitOurDisplay';
import * as Route from '@routes';
import WeSpecialise, { WeSpecialiseProps } from './Sections/WeSpecialise';
import GoodForYou, { GoodForYouProps } from './Sections/GoodForYou';
import OurStory, { OurStoryProps } from './Sections/OurStory';

export interface OurProcessProps {
  title: string;
  body: string;
  steps: StepsProps;
  top: WeSpecialiseProps;
  middle: GoodForYouProps;
  ourStory: OurStoryProps;
}

const OurProcess: React.FC<OurProcessProps> = ({
  title,
  body,
  steps,
  top,
  middle,
  ourStory,
}) => {
  return (
    <Box>
      <PageHeading title={title}>
        <Box htmlContent={body} />
      </PageHeading>

      <WeSpecialise {...top} />

      <Steps {...steps} />

      <OurStory {...ourStory} />

      <GoodForYou {...middle} />

      <UpNext
        links={[
          {
            label: 'Sustainability report',
            href: Route.sustainabilityReport(),
          },
          {
            label: 'Modular homes',
            href: Route.designs(),
          },
        ]}
      />
    </Box>
  );
};

export default OurProcess;
