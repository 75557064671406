import React from 'react';
import Box from '@webAtoms/Box/Box';
import UpNext from '@webMolecules/UpNext/UpNext';
import * as Route from '@routes';
import type { OurValuesSection } from './shared';
import { GoodForYou } from './Sections/GoodForYou';
import { Commitment } from './Sections/Commitment';
import { ReducesCarbonEmissions } from './Sections/ReducesCarbonEmissions';
import { DesignedForToday } from './Sections/DesignedForToday';
import { Reconciliation } from './Sections/Reconciliation';

import * as styles from './our_values.module.scss';

export interface OurValuesProps {
  title: string;
  sections: OurValuesSection[];
}

const OurValues: React.FC<OurValuesProps> = ({ title, sections }) => {
  const getSection = (section: OurValuesSection) => {
    switch (section.type) {
      case 'good-for-you':
        return <GoodForYou {...section} />;
      case 'commitment':
        return <Commitment {...section} />;
      case 'reduces-carbon-emissions':
        return <ReducesCarbonEmissions {...section} />;
      case 'designed-for-today':
        return <DesignedForToday {...section} />;
      case 'reconciliation':
        return <Reconciliation {...section} />;
      default:
        return null;
    }
  };

  return (
    <Box className={styles.ourValues}>
      {sections.map((section, index) => (
        <React.Fragment key={index}>{getSection(section)}</React.Fragment>
      ))}

      <UpNext
        links={[
          {
            label: 'Our process',
            href: Route.ourProcess(),
          },
          {
            label: 'Modular homes',
            href: Route.designs(),
          },
        ]}
      />
    </Box>
  );
};

export default OurValues;
