import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import Base from '@webLayouts/Base/Base';
import OurProcess from '@webPages/About/OurProcess/OurProcess';
import { PageOurProcessQuery } from '@generated/GraphqlTypes';
import { hyphensToNonBreakingHyphens } from '@helpers/string';

const OurProcessPage: React.FC<PageProps<PageOurProcessQuery>> = ({ data }) => {
  const title = data?.ourProcess?.frontmatter?.title || '';
  const body = hyphensToNonBreakingHyphens(data?.ourProcess?.html ?? '');

  const seoTitle = data?.ourProcess?.frontmatter?.seoTitle || undefined;
  const seoDescription =
    data?.ourProcess?.frontmatter?.seoDescription ||
    data?.ourProcess?.excerpt ||
    undefined;

  const image =
    data?.ourProcess?.frontmatter?.heroImage?.publicURL || undefined;

  const steps = {
    title: data?.ourProcess?.frontmatter?.subTitle || '',
    image: getImage(data?.ourProcess?.frontmatter?.heroImage as ImageDataLike),
    columns: (data?.ourProcess?.frontmatter?.columns || []).map(
      (column: any) => ({
        title: hyphensToNonBreakingHyphens(column.title ?? ''),
        body: hyphensToNonBreakingHyphens(column.body ?? ''),
        parts: (column.parts || []).map((part: any) => ({
          title: hyphensToNonBreakingHyphens(part.title ?? ''),
          body: hyphensToNonBreakingHyphens(part.body ?? ''),
        })),
      })
    ),
  };

  const top = {
    title: data?.ourProcess?.frontmatter?.namedSections?.top?.title || '',
    imageMobile: getImage(
      data?.ourProcess?.frontmatter?.heroImageMobile as ImageDataLike
    ),
    imageDesktop: getImage(
      data?.ourProcess?.frontmatter?.heroImage as ImageDataLike
    ),
    columns: (
      data?.ourProcess?.frontmatter?.namedSections?.top?.columns || []
    ).map((column: any) => ({
      title: column.title || '',
      body: column.body || '',
      linkText: column.linkText || undefined,
      linkUrl: column.linkUrl || undefined,
    })),
  };

  const middle = {
    title: data?.ourProcess?.frontmatter?.namedSections?.middle?.title || '',
    images: (
      data?.ourProcess?.frontmatter?.namedSections?.middle?.images || []
    ).map((a: unknown) => getImage(a as ImageDataLike) as IGatsbyImageData),
    parts: (
      data?.ourProcess?.frontmatter?.namedSections?.middle?.parts || []
    ).map((part: any) => ({
      title: part.title || '',
      body: part.body || '',
    })),
  };

  const ourStory = {
    title: data?.ourProcess?.frontmatter?.namedSections?.ourStory?.title || '',
    body: data?.ourProcess?.frontmatter?.namedSections?.ourStory?.body || '',
    image: getImage(
      data?.ourProcess?.frontmatter?.namedSections?.ourStory
        ?.image as ImageDataLike
    ) as IGatsbyImageData,
  };

  return (
    <Base
      theme="light"
      title={seoTitle}
      description={seoDescription}
      image={image}
    >
      <OurProcess
        title={title}
        body={body}
        steps={steps}
        top={top}
        middle={middle}
        ourStory={ourStory}
      />
    </Base>
  );
};

export default OurProcessPage;

export const query = graphql`
  query PageOurProcess {
    ourProcess: markdownRemark(
      fields: { collection: { eq: "pages" } }
      frontmatter: { page: { eq: "our-process" } }
    ) {
      html
      excerpt
      frontmatter {
        title
        seoTitle
        seoDescription
        subTitle
        heroImage {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 2000
              # placeholder: BLURRED
              # quality: 70
              # formats: [AUTO, WEBP, AVIF, JPG]
            )
          }
        }
        heroImageMobile {
          childImageSharp {
            gatsbyImageData(
              width: 500
              # placeholder: BLURRED
              # quality: 70
              # formats: [AUTO, WEBP, AVIF, JPG]
            )
          }
        }

        namedSections {
          top {
            title
            columns {
              title
              body
              linkText
              linkUrl
            }
          }
          middle {
            title
            images {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  # placeholder: BLURRED
                  # quality: 70
                  # formats: [AUTO, WEBP, AVIF, JPG]
                )
              }
            }
            parts {
              title
              body
            }
          }
          ourStory {
            title
            body
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 518
                  # placeholder: BLURRED
                  # quality: 100
                  # formats: [AUTO, WEBP, AVIF, JPG]
                )
              }
            }
          }
        }

        columns {
          title
          body
          parts {
            title
            body
          }
        }
      }
    }
  }
`;
