import React from 'react';
import Box, { Spacing } from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import Carousel from '@webOrganisms/Carousel/Carousel';
import * as styles from './paletteOptions.module.scss';
import Design from '@localTypes/Design';
import { GatsbyImage } from 'gatsby-plugin-image';
import Button from '@webAtoms/Button/Button';

export interface PaletteOptionsProps {
  design: Design;
}

const counts = {
  0: '',
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
};

const getPaletteCount = (count: number) => {
  if (count < 1 || count > 9) {
    return count;
  }
  return (counts as any)[count];
};

const PaletteOptions: React.FC<PaletteOptionsProps> = ({ design }) => {
  const palleteCount = getPaletteCount(design.palettes.length || 4);

  return (
    <Box className={styles.paletteOptions}>
      <Container>
        <Box className={styles.paletteOptionsInner}>
          <Box className={styles.paletteOptionsHeading}>
            <h2>{design.paletteTitle ?? 'Palette Options'}</h2>
            <p>
              {design.paletteBlurb ??
                `Select from ${palleteCount} beautiful, sophisticated and contemporary colour palettes that will set your mood in your new Ecoliv home.`}
            </p>

            <Box paddingTop={Spacing.M}>
              <Button
                intent="secondary"
                icon="arrowRight"
                href="/images/ecoliv-interiors-guide-2024.pdf"
              >
                Download interiors guide
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className={styles.paletteOptionsColumns}>
          {design.palettes.map(a => (
            <Box key={a.title} className={styles.paletteOptionsColumn}>
              <Box className={styles.paletteOptionsPalette}>
                <Carousel
                  type="inline"
                  slides={a.images.map(image => ({
                    slide: (
                      <GatsbyImage
                        loading="lazy"
                        alt={a.title}
                        image={image}
                        className={styles.paletteImage}
                      />
                    ),
                  }))}
                />
                <h3>{a.title}</h3>
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default PaletteOptions;
