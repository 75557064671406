import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import EnquireForm from '@webOrganisms/EnquireForm/EnquireForm';

export default ({ body, className }: { body?: string; className?: string }) => (
  <StaticQuery
    query={query}
    render={data => {
      const props = {
        accountName: `${data?.enquireForm?.frontmatter?.accountName}`,
        apiToken: `${data?.enquireForm?.frontmatter?.apiToken}`,
        formId: `${data?.enquireForm?.frontmatter?.formId}`,
        className,
      };
      return <EnquireForm {...props} />;
    }}
  />
);

export const query = graphql`
  query SectionEnquireForm {
    enquireForm: markdownRemark(
      fields: { collection: { eq: "sections" } }
      frontmatter: { name: { eq: "enquire-form" } }
    ) {
      frontmatter {
        accountName
        apiToken
        formId
      }
    }
  }
`;
