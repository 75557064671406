import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { PageSocialHousingQuery } from '@generated/GraphqlTypes';
import Base from '@webLayouts/Base/Base';
import Default from '@webPages/ModularHomes/Default/Default';

const IndexPage: React.FC<PageProps<PageSocialHousingQuery>> = ({ data }) => {
  const title = data?.socialHousing?.frontmatter?.title || '';

  const seoTitle =
    data?.socialHousing?.frontmatter?.seoTitle || title || undefined;
  const seoDescription =
    data?.socialHousing?.frontmatter?.seoDescription ||
    data?.socialHousing?.excerpt ||
    undefined;

  const image =
    data?.socialHousing?.frontmatter?.heroImage?.publicURL || undefined;

  const sections = (data?.socialHousing?.frontmatter?.sections || []).map(
    (section: any) => ({
      title: section?.title || '',
      body: section?.body || '',
      span: section?.span ? Number(section?.span) : 1,
      image: section?.image
        ? (getImage(section.image as ImageDataLike) as IGatsbyImageData) ||
          section.image.publicURL
        : undefined,
    })
  );
  const gridColumns =
    Number(data?.socialHousing?.frontmatter?.gridColumns) || 2;

  const socialHousing = {
    title,
    body: data?.socialHousing?.html || '',
    heroImage: getImage(
      data?.socialHousing?.frontmatter?.heroImage as ImageDataLike
    ) as IGatsbyImageData,
    gridColumns,
    sections,
    brochureForm: false,
  };

  return (
    <Base
      theme="light"
      title={seoTitle}
      description={seoDescription}
      image={image}
    >
      <Default {...socialHousing} />
    </Base>
  );
};

export const query = graphql`
  query PageSocialHousing {
    socialHousing: markdownRemark(
      fields: { collection: { eq: "pages" } }
      frontmatter: { page: { eq: "social-housing" } }
    ) {
      html
      excerpt
      frontmatter {
        title
        seoTitle
        seoDescription
        heroImage {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 2000
              height: 650
              # placeholder: BLURRED
              # quality: 70
              # formats: [AUTO, WEBP, AVIF, JPG]
            )
          }
        }

        # gridColumns

        sections {
          title
          body
          image {
            publicURL
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
          span
        }
      }
    }
  }
`;

export default IndexPage;
