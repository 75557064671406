import React from 'react';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import Icon from '@webAtoms/Icon/Icon';
import UpNext from '@webMolecules/UpNext/UpNext';
import Featured from '@localTypes/Featured';
import { GatsbyImage } from 'gatsby-plugin-image';
import ModularHomeDesigns from '@webOrganisms/ModularHomeDesigns/ModularHomeDesigns';
import * as Route from '@routes';
import * as styles from './featuredBuilds.module.scss';
import { titleCase, ucfirst } from '@helpers/slug';

export interface FeaturedBuildsProps {
  title: string;
  body: string;
  featured: Featured[];
}

interface FeaturedBuildsState {
  category: string;
  filteredFeatured: Featured[];
}

const FeaturedBuilds: React.FC<FeaturedBuildsProps> = ({
  title,
  body,
  featured,
}) => {
  const tagCloudOptions = [
    {
      tag: '',
      title: 'All',
      seoTitle: 'Featured Eco-Friendly Homes & Custom Projects | Ecoliv',
      href: Route.featuredBuilds(),
    },
    {
      tag: 'single-storey',
      title: 'Single-storey',
      seoTitle: 'Featured Single Storey Custom Projects | Ecoliv',
      href: Route.featuredBuildsSingleStorey(),
    },
    {
      tag: 'double-storey',
      title: 'Double-storey',
      seoTitle: 'Featured Double Storey Custom Projects | Ecoliv',
      href: Route.featuredBuildsDoubleStorey(),
    },
    {
      tag: 'on-site',
      title: 'On-site',
      seoTitle: 'Featured On Site Custom Projects | Ecoliv',
      href: Route.featuredBuildsOnSite(),
    },
    {
      tag: 'off-grid',
      title: 'Off-grid',
      seoTitle: 'Featured Off Grid Custom Projects | Ecoliv',
      href: Route.featuredBuildsOffGrid(),
    },
    {
      tag: 'extensions',
      title: 'Extensions',
      seoTitle: 'Featured Extensions Custom Projects | Ecoliv',
      href: Route.featuredBuildsExtensions(),
    },
    {
      tag: 'secondary-dwellings',
      title: 'Secondary Dwellings',
      seoTitle: 'Featured On Secondary Dwellings Projects | Ecoliv',
      href: Route.featuredBuildsSecondaryDwellings(),
    },
  ];

  const allTags = [
    ...featured.reduce<Set<string>>((acc, item) => {
      item.tags.forEach(tag => acc.add(tag));
      return acc;
    }, new Set()),
  ];

  allTags.forEach(tag => {
    if (
      !tagCloudOptions.find(
        a => a.tag === tag.toLowerCase().replace(/\s/g, '-')
      )
    ) {
      tagCloudOptions.push({
        tag,
        title: titleCase(tag),
        seoTitle: 'Featured Eco-Friendly Homes & Custom Projects | Ecoliv',
        href: Route.featuredBuildsCategory(tag),
      });
    }
  });

  const [category, setCategory] = React.useState('');
  const changeCategory = (category = '') => {
    setCategory(category);
    window.history.replaceState(
      null,
      '',
      `${Route.featuredBuildsCategory(category)}`
    );
  };

  React.useEffect(() => {
    const searchParams = new URLSearchParams(
      typeof window !== 'undefined' ? window.location.search : ''
    );
    const category = searchParams.get('category') ?? '';
    setCategory(category);
  }, []);

  const refTags = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (refTags.current) {
      const wrapper = refTags.current;
      wrapper.scroll({
        left:
          wrapper.querySelector('[class*="active"]')?.getBoundingClientRect()
            .left ?? 0,
      });
    }
  }, [refTags]);

  const filteredFeatured = category
    ? featured.filter(a => a.tags.includes(category))
    : featured;

  return (
    <>
      <Box>
        <Container>
          <Box className={styles.featuredBuildsHeading}>
            <Box className={styles.featuredBuildsHeadingColumn}>
              <h1>{title}</h1>
              <div className={styles.featuredBuildsTags} ref={refTags}>
                <ul>
                  {tagCloudOptions.map(option => (
                    <li
                      key={option.tag}
                      className={category === option.tag ? styles.active : ''}
                    >
                      <a
                        href={option.href}
                        onClick={() => {
                          changeCategory(option.tag);
                        }}
                      >
                        {option.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </Box>
            <Box
              className={styles.featuredBuildsHeadingColumn}
              htmlContent={body}
            />
          </Box>
        </Container>

        <Container>
          <Box className={styles.featuredBuildsItems}>
            {filteredFeatured.map(item => (
              <a
                key={item.slug}
                className={styles.featuredBuildsItem}
                href={`${Route.featuredBuilds(item.slug)}`}
              >
                <Box className={styles.featuredBuildsItemImageWrapper}>
                  <GatsbyImage
                    loading="lazy"
                    alt={item.title}
                    image={item.image}
                  />
                </Box>
                <div className={styles.featuredBuildsItemLink}>
                  <h2>
                    {item.title} <Icon name="arrowRight" />
                  </h2>
                  <ul>
                    {item.tags.map(category => (
                      <li key={category}>
                        <span>#{category}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </a>
            ))}
          </Box>
        </Container>
      </Box>

      <ModularHomeDesigns />

      <UpNext
        links={[
          { label: 'Our approach', href: Route.ourApproach() },
          { label: 'Modular homes', href: Route.designs() },
        ]}
      />
    </>
  );
};

export default FeaturedBuilds;
