import * as React from 'react';

import Box from '@webAtoms/Box/Box';
import type { OurValuesSection } from '../shared';
import * as styles from '../our_values.module.scss';
import Container from '@webAtoms/Container/Container';
import { render } from '@helpers/markdown';

export const DesignedForToday = ({
  image,
  body,
  sections,
  title,
}: OurValuesSection) => {
  const downloads = sections.filter(section => Boolean(section.download));

  return (
    <Box className={styles.designedForToday}>
      <Container>
        <Box className={styles.inner}>
          <Box className={styles.content}>
            <h2>{title}</h2>
            <Box htmlContent={render(body ?? '')} />
          </Box>
          <Box className={styles.content}>
            {/* {image && (
              <GatsbyImage
                image={image.childImageSharp.gatsbyImageData}
                alt={title}
              />
            )} */}
            {image && <img src={image.publicURL} alt={title} />}

            <Box className={styles.sections}>
              {downloads.map((download, index) => (
                <Box key={index} className={styles.section}>
                  <h4>{download.title}</h4>
                  <a href={download.download?.publicURL} download>
                    {download.body}
                  </a>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
