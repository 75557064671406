import React from 'react';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import * as styles from './column.module.scss';
import { render } from '@helpers/markdown';
import ReadMore from '@webMolecules/ReadMore/ReadMore';

export interface ColumnProps {
  title?: string;
  body?: string;
  image?: string;
  file?: string;
  totalColumns?: number;
}

const Column: React.FC<ColumnProps> = props => {
  const { title, body = '', image, file, totalColumns = 1 } = props;
  if (file && title?.startsWith('FILE')) {
    return <ColumnFile {...props} />;
  }
  if (title?.toLowerCase().includes('reconciliation action plan')) {
    return <ColumnFile {...props} file="/images/ecoliv-rap-2024-int.pdf" />;
  }
  return (
    <Box className={styles.column}>
      <Container>
        <Box className={styles.columnContent}>
          <Box
            className={[
              totalColumns > 2
                ? styles.columnContentHeaderSmall
                : styles.columnContentHeader,
              image ? 'has-image' : '',
            ].join(' ')}
          >
            <h2>{title}</h2>
          </Box>
          {image && (
            <Box className={styles.columnContentImageWrapper}>
              {<img src={image} alt={title} />}
            </Box>
          )}
          <Box
            className={styles.columnContentBody}
            htmlContent={render(body)}
          />
        </Box>
      </Container>
    </Box>
  );
};

const ColumnFile: React.FC<ColumnProps> = ({
  title,
  body = '',
  image,
  file,
  totalColumns = 1,
}) => {
  const localTitle = (title ?? '').replace('FILE', '').trim();
  return (
    <Box className={[styles.column, styles.columnFile].join(' ')}>
      <Container>
        <Box className={styles.columnContent}>
          <Box
            className={[
              totalColumns > 2
                ? styles.columnContentHeaderSmall
                : styles.columnContentHeader,
              image ? 'has-image' : '',
            ].join(' ')}
          >
            <h2>{localTitle}</h2>
          </Box>
          <Box className={styles.columnFileDownload}>
            <ReadMore href={file}>Review our {localTitle} here</ReadMore>
          </Box>
          <Box>
            {image && (
              <a href={file}>
                <img src={image} alt={localTitle} />
              </a>
            )}
          </Box>
          <Box
            className={styles.columnContentBody}
            htmlContent={render(body)}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default Column;
