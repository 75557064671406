import * as React from 'react';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import type { OurValuesSection } from '../shared';

import PngTree from '../images/trees.png';
import PngHouse from '../images/house.png';
import PngCar from '../images/car.png';
import PngBalloon from '../images/balloon.png';

import * as styles from '../our_values.module.scss';

const INFOGRAPHIC_ICONS = [
  ['%TREE', `<img src="${PngTree}" />`],
  ['%HOUSE', `<img src="${PngHouse}" />`],
  ['%CAR', `<img src="${PngCar}" />`],
  ['%BALLOON', `<img src="${PngBalloon}" />`],
];

export const ReducesCarbonEmissions = ({
  image,
  body,
  sections,
  title,
}: OurValuesSection) => {
  body = INFOGRAPHIC_ICONS.reduce(
    (acc, [a, b]) => acc.replace(new RegExp(a, 'g'), b),
    body ?? ''
  );

  const [state, setState] = React.useState<{ [key: string]: boolean }>({});

  const onToggle = (key: string) => {
    setState(prev => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <Box className={styles.reduces}>
      <Container>
        <Box className={styles.inner}>
          <Box className={styles.content}>
            <h2>{title}</h2>
            <Box className={styles.contentBody} htmlContent={body} />
          </Box>

          <div className={styles.side}>
            <Box className={styles.sections}>
              {sections.map((section, index) => (
                <Box key={index} className={styles.section}>
                  <button
                    className={`${styles.toggleCollapisble} ${
                      state[section.title] ? styles.open : ''
                    }`}
                    onClick={() => onToggle(section.title)}
                  >
                    {section.title}

                    <span />
                  </button>
                  <div className={styles.interlude}>
                    <Box htmlContent={section.body ?? ''} />
                  </div>
                  <div
                    className={`${styles.sectionCollapsible} ${
                      state[section.title] ? styles.open : ''
                    }`}
                  >
                    <div className={styles.sectionCollapsibleInner}>
                      {section.sections.map((subSection, index) => (
                        <Box key={index} className={styles.subSection}>
                          <h3>{subSection.title}</h3>
                          <Box htmlContent={subSection.body ?? ''} />
                        </Box>
                      ))}
                    </div>
                  </div>
                </Box>
              ))}
            </Box>
          </div>
        </Box>
      </Container>
    </Box>
  );
};
