import React from 'react';
import Box, { Spacing } from '@webAtoms/Box/Box';
import UpNext from '@webMolecules/UpNext/UpNext';
import * as Route from '@routes';
import Container from '@webAtoms/Container/Container';
import * as styles from './requestFreeSiteAssessment.module.scss';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Button from '@webAtoms/Button/Button';
import { useCalendlyLinks } from '@helpers/useCalendlyLinks';
import PageHeading from '@webMolecules/PageHeading/PageHeading';
import RequestFreeSiteAssessmentForm from '@webSections/RequestFreeSiteAssessmentForm';

export interface RequestFreeSiteAssessmentProps {
  body: string;
  image?: IGatsbyImageData;
  buttons: { label: string; url: string }[];
}

const RequestFreeSiteAssessment: React.FC<RequestFreeSiteAssessmentProps> = ({
  body,
  buttons,
  image,
}) => {
  const buttonsRef = React.useRef<HTMLDivElement>(null);
  useCalendlyLinks(buttonsRef);

  return (
    <Container>
      <PageHeading
        className={styles.pageHeading}
        title="Request a free site assessment"
        children={
          <div className={styles.buttons} ref={buttonsRef}>
            {buttons.map((b, i) => (
              <Box key={i}>
                <Button
                  title={b.label}
                  href={b.url}
                  target="_blank"
                  intent="secondary"
                  icon="arrowRight"
                >
                  {b.label}
                </Button>
              </Box>
            ))}
          </div>
        }
      />
      <Box
        className={styles.requestFreeSiteAssessmentLayout}
        padding={Spacing.S}
      >
        <Box paddingBottom={Spacing.S}>
          <RequestFreeSiteAssessmentForm
            className={styles.enquireForm}
            body={body}
          />
        </Box>
        <div className={styles.imageContainer}>
          {image && (
            <GatsbyImage loading="lazy" image={image} alt="Contact us" />
          )}
        </div>
      </Box>
      <UpNext
        links={[
          { label: 'Countact us', href: Route.contactUs() },
          { label: 'Modular homes', href: Route.designs() },
        ]}
      />
    </Container>
  );
};

export default RequestFreeSiteAssessment;
