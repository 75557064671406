import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';
import Base from '@webLayouts/Base/Base';
import SustainabilityReport from '@webPages/About/SustainabilityReport/SustainabilityReport';
import { PageSustainabilityReportQuery } from '@generated/GraphqlTypes';

const SustainabilityReportPage: React.FC<
  PageProps<PageSustainabilityReportQuery>
> = ({ data }) => {
  const title = data?.sustainability?.frontmatter?.title ?? '';

  const seoTitle = data?.sustainability?.frontmatter?.seoTitle || undefined;
  const seoDescription =
    data?.sustainability?.frontmatter?.seoDescription ||
    data?.sustainability?.excerpt ||
    undefined;

  const image =
    data?.sustainability?.frontmatter?.heroImage?.publicURL || undefined;

  const designedForToday = {
    image: getImage(
      data?.sustainability?.frontmatter?.heroImage as ImageDataLike
    ),
    title: data?.sustainability?.frontmatter?.subTitle || '',
    body: data?.sustainability?.html || '',
  };

  const sustainabilityPdf =
    data?.sustainability?.frontmatter?.sustainabilityPdf?.publicURL || '';

  const report = {
    sustainabilityPdf,
    sections: (data?.sustainability?.frontmatter?.sections || []).map(
      (section: any) => {
        return {
          title: section?.title ?? '',
          body: section?.body ?? '',
          columns: (section?.columns ?? []).map((column: any, c: number) => ({
            parts: (column?.parts ?? []).map((part: any) => ({
              title: part?.title ?? '',
              body: part?.body ?? '',
              image:
                c > 0 && part?.image
                  ? getImage(part?.image as ImageDataLike)
                  : undefined,
              linkUrl: part?.linkUrl ?? undefined,
              linkFile: part?.linkFile?.publicURL ?? undefined,
              linkText: part?.linkText ?? undefined,
              linkToPDF: part?.linkToPDF ?? undefined,
            })),
          })),
        };
      }
    ),
  };

  return (
    <Base
      theme="primary-all"
      title={seoTitle}
      description={seoDescription}
      image={image}
    >
      <SustainabilityReport
        title={title}
        designedForToday={designedForToday}
        report={report}
      />
    </Base>
  );
};

export default SustainabilityReportPage;

export const query = graphql`
  query PageSustainabilityReport {
    sustainability: markdownRemark(
      fields: { collection: { eq: "pages" } }
      frontmatter: { page: { eq: "sustainability" } }
    ) {
      html
      excerpt
      frontmatter {
        title
        seoTitle
        seoDescription
        subTitle
        heroImage {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 2000
              # placeholder: BLURRED
              # quality: 70
              # formats: [AUTO, WEBP, AVIF, JPG]
            )
          }
        }
        # sustainabilityPdf {
        #   publicURL
        # }
        sections {
          title
          body
          columns {
            parts {
              title
              body
              linkUrl
              linkText
              linkToPDF
              image {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 2000
                    # placeholder: BLURRED
                    # quality: 70
                    # formats: [AUTO, WEBP, AVIF, JPG]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;
