import React from 'react';
import { Formik, Field, Form, FieldProps } from 'formik';
import Box, { Spacing } from '@webAtoms/Box/Box';
import Button from '@webAtoms/Button/Button';
import Loading from '@webAtoms/Loading/Loading';
import FloatingLabelInput from '@webMolecules/FloatingLabelInput/FloatingLabelInput';
import FloatingLabelSelect from '@webMolecules/FloatingLabelSelect/FloatingLabelSelect';
import LabeledTextarea from '@webMolecules/LabeledTextarea/LabeledTextarea';
import LabeledCheckbox from '@webMolecules/LabeledCheckbox/LabeledCheckbox';
import * as styles from './requestFreeSiteAssessmentForm.module.scss';
import * as Yup from 'yup';
import { toURLParams } from '@helpers/form';
import { StaticImage } from 'gatsby-plugin-image';
import { trackEnquireSubmit } from '@helpers/track';
import Text, { FontStyle } from '@webAtoms/Text/Text';
import {
  ActiveCampaignFormOptions,
  useActiveCampaignForm,
} from 'src/hooks/useActiveCampaignForm';
import md5 from 'md5';

const DEFAULT_ACCOUNT_NAME = 'ecoliv40425';
const DEFAULT_API_TOKEN =
  'e0a3d8e7c48e7c923a9733ce514b057062edb23f7c139bb9cd232b411cb2c03588441417';
const DEFAULT_FORM_ID = '47';

export type RequestFreeSiteAssessmentFormProps = {
  className?: string;
} & Partial<ActiveCampaignFormOptions>;

export interface RequestFreeSiteAssessmentFormField {
  type: string;
  label: string;
  name?: string;
  options?: { value: string; label: string }[];
  required?: boolean;
}

const RequestFreeSiteAssessmentForm: React.FC<RequestFreeSiteAssessmentFormProps> =
  React.memo(
    props => {
      const formOptions = {
        accountName: props?.accountName ?? DEFAULT_ACCOUNT_NAME,
        apiToken: props?.apiToken ?? DEFAULT_API_TOKEN,
        formId: props?.formId ?? DEFAULT_FORM_ID,
      };
      const activeCampaignForm = useActiveCampaignForm(formOptions);

      const [formState, setFormState] = React.useState<
        'idle' | 'busy' | 'success'
      >('idle');

      if (activeCampaignForm.type === 'loading') {
        return (
          <Box className={styles.loadingContainer}>
            <Loading />
          </Box>
        );
      } else if (activeCampaignForm.type === 'error') {
        return (
          <Box className={styles.loadingContainer}>
            <Loading />
            <p>{activeCampaignForm.error}</p>
          </Box>
        );
      }

      if (formState === 'success') {
        return (
          <Box className={styles.success}>
            <Box className={styles.successBody}>
              <StaticImage src={'./tick.svg'} alt="success" />
              <Box
                className={styles.successBodyText}
                htmlContent={
                  activeCampaignForm.form.thankYouMessage?.replace(
                    '\n',
                    '<br />'
                  ) || ''
                }
              />
            </Box>
          </Box>
        );
      }

      const initialFormValues: Record<string, string | boolean> = {};
      const validationSchemaItems: Record<string, any> = {};
      activeCampaignForm.form.fields.forEach(field => {
        switch (field.type) {
          case 'input':
          case 'select':
          case 'checkbox':
          case 'boolean':
            if (field.required && field.name === 'email') {
              validationSchemaItems[field.name] = Yup.string()
                .email('Invalid email')
                .required('Required');
            } else if (field.required) {
              validationSchemaItems[field.name] =
                Yup.string().required('Required');
            }
        }
      });

      const validationSchema = Yup.object().shape(validationSchemaItems);

      const onSubmit = (formValues: { [key: string]: string | boolean }) => {
        setFormState('busy');
        if (formState === 'busy') {
          return;
        }

        const keys = Object.keys(formValues);
        const data = new Array();
        const netlifyData = new FormData();

        keys.forEach(key => {
          let dataKey = key;
          if (!Number.isNaN(Number(key))) {
            dataKey = `field[${key}]`;
          }

          let value = (formValues as any)[key];
          netlifyData.append(key, value);
          if (value === true) {
            dataKey = `${dataKey}[]`;
            value = 'Yes';
          } else if (value === false) {
            dataKey = `${dataKey}[]`;
            value = 'No';
          }
          data.push(`${dataKey}=${value}`);
        });

        fetch(
          `https://${formOptions.accountName}.activehosted.com/proc.php?u=${
            formOptions.formId
          }&f=${formOptions.formId}&s=&c=0&m=0&act=sub&v=2&or=${md5([
            new Date().getTime(),
          ])}&jsonp=true&${data.join('&')}`,
          {
            method: 'GET',
            mode: 'no-cors',
          }
        )
          .then(() => {
            trackEnquireSubmit();
            setFormState('success');
          })
          .catch(error => console.error('Request failed', error));

        try {
          netlifyData.append('form-name', `form${formOptions.formId}`);
          fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: toURLParams(netlifyData),
          });
        } catch (e) {
          console.error(e);
        }
      };

      return (
        <Formik
          initialValues={initialFormValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ submitForm, errors, touched, submitCount }) => (
            <Form
              className={`${props.className ?? styles.defaultForm} ${
                styles.form
              } form`}
            >
              {activeCampaignForm.form.fields.map((field, i) => {
                switch (field.type) {
                  case 'header': {
                    if (i < 3) {
                      return <h1 key={i}>{field.label}</h1>;
                    }
                    return <h5 key={i}>{field.label}</h5>;
                  }
                  case 'html':
                    return (
                      <Box
                        key={i}
                        style={{ paddingBottom: 'var(--spacing--l)' }}
                        htmlContent={field.html}
                      />
                    );

                  case 'input':
                    return (
                      <Field key={i} name={field.name}>
                        {(props: FieldProps) => (
                          <FloatingLabelInput
                            type={
                              field.name === 'email'
                                ? 'email'
                                : field.name === 'phone'
                                ? 'tel'
                                : 'text'
                            }
                            label={field.label}
                            touched={
                              submitCount > 0 || !!touched[field.name ?? '']
                            }
                            error={props.form.errors[field.name ?? '']}
                            {...props.field}
                          />
                        )}
                      </Field>
                    );

                  case 'textarea':
                    return (
                      <Field key={i} name={field.name}>
                        {(props: FieldProps) => (
                          <LabeledTextarea
                            label={field.label}
                            placeholder="Type here..."
                            {...props.field}
                          />
                        )}
                      </Field>
                    );

                  case 'select':
                  case 'checkbox':
                  case 'dropdown':
                    return (
                      <Field key={i} name={field.name}>
                        {(props: FieldProps) => (
                          <FloatingLabelSelect
                            label={field.label}
                            touched={
                              submitCount > 0 || !!touched[field.name ?? '']
                            }
                            error={props.form.errors[field.name ?? '']}
                            {...props.field}
                            options={field.options || []}
                          />
                        )}
                      </Field>
                    );

                  case 'boolean':
                    return (
                      <Field key={i} name={field.name}>
                        {(props: FieldProps) => (
                          <LabeledCheckbox
                            label={field.label}
                            {...props.field}
                          />
                        )}
                      </Field>
                    );
                }
              })}

              <Box className={styles.formSubmitWrapper}>
                <Button
                  icon="arrowRight"
                  intent="primary"
                  disabled={formState === 'busy'}
                  onClick={submitForm}
                >
                  {formState === 'busy' ? (
                    <Loading />
                  ) : (
                    activeCampaignForm.form.submitButtonLabel
                  )}
                </Button>

                {Object.keys(errors).length > 0 && (
                  <Box paddingTop={Spacing.M}>
                    <Text fontStyle={FontStyle.BodyTiny} color={'red'}>
                      We found some errors in your form. Please check/correct
                      and try again.
                    </Text>
                  </Box>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      );
    },
    () => true
  );

export default RequestFreeSiteAssessmentForm;
