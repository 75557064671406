import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import RequestFreeSiteAssessmentForm from '@webOrganisms/RequestFreeSiteAssessmentForm/RequestFreeSiteAssessmentForm';

export default ({ body, className }: { body?: string; className?: string }) => (
  <StaticQuery
    query={query}
    render={data => {
      const props = {
        accountName: `${data?.requestFreeSiteAssessmentForm?.frontmatter?.accountName}`,
        apiToken: `${data?.requestFreeSiteAssessmentForm?.frontmatter?.apiToken}`,
        formId: `${data?.requestFreeSiteAssessmentForm?.frontmatter?.formId}`,
        className,
      };
       return <RequestFreeSiteAssessmentForm {...props} />;
    }}
  />
);

export const query = graphql`
  query RequestFreeSiteAssessmentForm {
    requestFreeSiteAssessmentForm: markdownRemark(
      fields: { collection: { eq: "sections" } }
      frontmatter: { name: { eq: "request-free-site-assessment-form" } }
    ) {
      frontmatter {
        accountName
        apiToken
        formId
      }
    }
  }
`;
